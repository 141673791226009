import { apiUrl } from './Api';
import Axios from 'axios';

export async function findAllClients(auth) {
    try {
        return await Axios.get(`${apiUrl}/clients/findAll`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllAndProposals(auth, stateId, saId, year) {
    try {
        return await Axios.get(`${apiUrl}/clients/findAllAndProposals`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
            params: { stateId, saId, year },
        });
    } catch (err) {
        return err;
    }
}   

export async function importClients(auth, stateId) {
    try {
        return await Axios.post(
            `${apiUrl}/states/${stateId}/clients/importClients`,
            {},
            {
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findAllClientsFromState(auth, stateId, hasSaNull) {
    try {
        hasSaNull = Number(hasSaNull);
        return await Axios.get(`${apiUrl}/states/${stateId}/clients`, {
            params: { hasSaNull },
            headers: {
                Authorization: `Bearer ${auth.data.token}`,
            },
        });
    } catch (err) {
        return err;
    }
}

export async function findClientById(auth, clientId) {
    try {
        return await Axios.get(`${apiUrl}/clients/${clientId}/findClientById`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}
